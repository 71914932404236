<template>
	<!-- TITULO DEL TOOLBAR -->
	<v-toolbar
	  class="elevation-0"
	  color="#00A548"
	  dark
	  dense
	  tile
	>
	  {{ nameTwilio }}

	  <v-spacer></v-spacer>

	  <v-menu
	    v-if="[334,1,568,32,718, 1183, 1258].includes( getdatosUsuario.iderp ) || vendedorasTwilio.length"
	    top
	    offset-y
	  >
	    <template v-slot:activator="{ on, attrs }">
	      <v-btn
	        color="white"
	        v-bind="attrs"
	        v-on="on"
	        class="mr-3 ml-4"
	        x-small
	        fab
	      >
	        <v-icon color="green">mdi-account</v-icon>
	      </v-btn>
	    </template>

	    <v-list dense v-if="!vendedorasTwilio.length">
	      <v-list-item
	        v-if="!vendedorasTwilio.length"
	        v-for="(item, index) in vendedoras_plantel"
	        :key="index"
	        @click="$emit('seleccionaVendedora',item)"
	      >
	        <v-list-item-title class="text-caption">
	          <v-icon color="grey">mdi-account</v-icon>
	          {{ item.nombre }}
	        </v-list-item-title>
	      </v-list-item>
	    </v-list>

	    <v-list dense v-else>
	      <v-list-item
	        v-for="(item, index) in vendedorasTwilio"
	        :key="index"
	        @click="$emit('seleccionaVendedora',item)"
	      >
	        <v-list-item-title class="text-caption">
	          <v-icon color="grey">mdi-account</v-icon>
	          {{ item.nombre }}
	        </v-list-item-title>
	      </v-list-item>
	    </v-list>
	  </v-menu>

	  <v-btn 
	  	x-small
	  	@click="$emit('abrirAjustesMensajes')" 
	  	class="mr-3"
	  	fab
	  	color="white"
	  >
	  	<v-icon color="green">mdi-message-cog</v-icon>
	  </v-btn>

	  <v-btn 
	  	x-small
	  	@click="$emit('initialize')" 
	  	class="mr-2"
	  	fab
	  	color="white"
	  >
	  	<v-icon color="green">mdi-refresh</v-icon>
	  </v-btn>


	</v-toolbar>
</template>
</template>
<script>
  import axios          from 'axios';
  import { mapGetters, mapActions } from 'vuex'


  export default {

  	props:[
  		'nameTwilio',
  		'vendedorasTwilio',
  		'filtroVendedora',
  		'vendedoras_plantel'
  	],

    data: () => ({

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

    },

    methods:{

    }

  }
</script>