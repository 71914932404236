<template>
	<v-dialog
    v-model="openDialogMensajes.estatus"
    persistent
    max-width="1200"
  >
    <v-card>
      <v-card-title primary-title >
        Genera tus mensajes predeterminados
        <v-spacer></v-spacer>
        <v-btn 
        	color="orange"
        	@click="dialog = true"
        	small
        	tile
        	dark
        	class="mr-2"
        >
      		Agregar
      		<v-icon left right>mdi-plus</v-icon>
      	</v-btn>

        <v-btn 
          color="primary"
          @click="initialize"
          small
          tile
          dark
          class="mr-10"
        >
          Actualizar
          <v-icon left right>mdi-refresh</v-icon>
        </v-btn>

      </v-card-title>

      <v-card-text class="black--text">
        <v-data-table
			    :headers="headers"
			    :items="mensajes"
			    class="elevation-0"
			    :search="search"
          dense
			  >
			    <template v-slot:item.activo_sn="{ item }">
			      <v-chip 
			      	v-if="item.activo_sn == 1"
			      	small
			      	color="green"
			      	dark
			      >Si</v-chip>

			      <v-chip 
			      	v-else
			      	small
			      	color="error"
			      	dark
			      >No</v-chip>
			    </template>

			  </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn 
          color="black"   
          dark
          @click="openDialogMensajes.estatus = false"
          icon
          absolute
          top
          right
        >
          <v-icon left>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
          color="success" 
          dark 
          small 
          @click="asignarVendedora()"
          tile
        >
          <v-icon small left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>

          <label>Agrega un titulo para reconocer el mensaje</label>
          <v-text-field
            v-model="editedItem.titulo"
            filled
            single-line
            dense
          ></v-text-field>
            
          <label>Agrega un mensaje</label>
          <v-textarea
            v-model="editedItem.mensaje"
            filled
            dense
            :rows="3"
            auto-grow
          ></v-textarea>

          <v-radio-group
            v-model="opArchivo"
            row
          >
            <v-radio
              label="Sin archivo"
              :value="1"
            ></v-radio>
            <v-radio
              label="PDF, VIDEO, IMAGEN, DOC"
              :value="2"
            ></v-radio>
            <v-radio
              label="Audio"
              :value="3"
            ></v-radio>
          </v-radio-group>

          <div v-if="!file && opArchivo == 2" >
            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                <div class="dropZone-upload-limit-info">
                  <div>Tamaño máximo: 40 MB</div>
                </div>
              </div>
              <input type="file" @change="onChange">
            </div>
          </div>
         
          <div v-else-if="opArchivo == 2">
            <v-card class="elevation-0 transparent">
              <img v-if="vistaPrevia && tipoArchivo == 2" :src="vistaPrevia" alt="Red dot" width="100%"/>
              <iframe 
                v-if="vistaPrevia && tipoArchivo == 4"
                :src="`${vistaPrevia}#toolbar=0`" 
                width="100%" 
                height="300px" 
                style="border: none;"
              ></iframe>

            </v-card>
          </div>
          
        </v-card-text>

        <v-card-actions>
          <v-btn
            icon
            top
            absolute
            right
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            small
            tile
            @click="save"
            dark
          >
            Guardar
            <v-icon small left>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'openDialogMensajes',
			'alumno',
			'wha',
			'chat',
			'usuarios'
	  ],

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search:'',
      mensajes: [],
      headers: [
        { text: 'ID'        , value: 'idmensajes_predeterminados'   },
        { text: 'Titulo'    , value: 'titulo'      },
        { text: 'Mensaje'   , value: 'mensaje'     },
        { text: 'Archivo'   , value: 'archvio'     },
        { text: 'type'      , value: 'type'        },
        { text: 'Actions'   , value: 'actions'     },
      ],

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        idmensajes_predeterminados: 0,
        titulo: '',
        mensaje: '',
        archvio: '',
        type: 1,
        deleted:0
      },
      defaultItem: {
        idmensajes_predeterminados: 0,
        titulo: '',
        mensaje: '',
        archvio: '',
        type: 0,
        deleted:0
      },

      archivos:null,
      vistaPrevia: null,
      file: '',
      dragging: false,

      opArchivo: 1,
      tipoArchivo:0,

    }),


    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Mensaje' : 'Editar Mensaje'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created( ){

    	this.initialize( )

    },

    methods:{

    	initialize( ){
    		
    		// Preparamos el payalos
        this.cargar = true

        // Hacemos la petición y enviamos el payload
		    return this.$http.get('mensajes.predeterminados/' + this.getdatosUsuario.iderp ).then(response=>{

		    	// Abrimos alerta de que todo corrió bien
          this.mensajes = response.data

		      // Cerramos el loader
		      this.cargar      = false

		      // Limpiamos el usuario
		      this.usuario = null

		    }).catch( error =>{
		      this.validarError( error.response.data.message )
		    }).finally( () => { this.cargar = false })

    	},

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {

        // Validamos el tipo de mensaje que es
        this.editedItem['type']       = this.opArchivo == 1 ? 1 : 0
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp

        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          // Preparamos el payalos
          this.cargar = true

          // Hacemos la petición y enviamos el payload
          return this.$http.post('mensajes.predeterminados.add', this.editedItem ).then(response=>{

            this.validarSuccess('Mensaje generado correctamente')
            // Abrimos alerta de que todo corrió bien
            this.close( )
            this.initialize( )

            // Cerramos el loader
            this.cargar      = false

            // Limpiamos el usuario
            this.usuario = null

          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }
        this.close()
      },


      getBase64(file) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          
          if( file ){

            const nombreSplite = file.name.split('.')

            const exstension = nombreSplite[ nombreSplite.length -1 ]

            // Validamos si son imagenes 
            this.tipoArchivo = ['jpg','png','PNG','webp','svg'].includes( exstension ) ? 2 : this.tipoArchivo

            // Validamos si son imágenes
            this.tipoArchivo = ['PDF','pdf'].includes( exstension ) ? 4 : this.tipoArchivo

          }

          this.vistaPrevia = reader.result

        };
      },

      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          this.dragging = false;
          return;
        }
        
        this.createFile(files[0]);
      },

      createFile(file) {
        if (file.size > 40000000) {
          alert('please check file size no over 40 MB.')
          this.dragging = false;
          return;
        }
        
        this.file = file;
        this.getBase64(this.file)
        this.dragging = false;
      },

      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          this.dragging = false;
          return;
        }
        
        this.createFile(files[0]);
      },

    }
	}
</script>

<style  scoped>

  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }



</style>

